<template>
  <component
    :is="variant"
    class="badge"
    :style="{
      '--badge-background-color': colors[type],
      '--badge-color': colors[`t${type}`],
      '--badge-border-color': colors[`b${type}`],
      '--badge-cursor': cursorPointer ? 'pointer' : 'unset',
    }"
  >
    <slot />
  </component>
</template>

<script>
import colors from '@/styles/colors';

export default {
  name: 'AppBadge',
  props: {
    variant: {
      type: String,
      default: 'div',
    },
    type: {
      type: String,
      default: 'default',
    },
    cursorPointer: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      colors,
    };
  },
};
</script>

<style scoped lang="scss">
.badge {
  --background-color: #fff;
  --color: #000;
  padding: 3px 8px;
  background-color: var(--badge-background-color);
  color: var(--badge-color);
  border-radius: 4px;

  border: 1px solid var(--badge-border-color);

  cursor: var(--badge-cursor);

  @include flex-center;

  height: 24px;

  :deep(.text) {
    display: inline-block;
    text-align: center;
  }
}
</style>
